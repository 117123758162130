import { useCallback, useEffect, useState } from 'react';
import { t, getLanguage } from 'react-switch-lang';
import { useRouter } from 'next/router';
import { AUTH_API } from './Constants';
import { logAmpEvent, events } from './Amplitude';
import { moveFocusTo, setScreenReaderAlert } from './Accessibility';

// custom error messages are returned in format "Error: error msg here"
const handleCognitoCustomError = (msg) => {
  const customErrorIdentifier = '[ERROR]';
  if (!msg) return false;
  if (msg.length < 6) return false;
  const customErrIndex = msg.indexOf(customErrorIdentifier);
  if (customErrIndex > -1) {
    return msg.substr(customErrIndex + customErrorIdentifier.length + 1);
  }
  return t('Error_Default');
};

function parseCognitoError(err, call) {
  // check language file for any custom error message overrides
  const langFileError = t(`Error_Cognito_${err.code}`);
  if (langFileError !== `Error_Cognito_${err.code}`) return langFileError;

  // special handling
  switch (err.code) {
    case 'NotAuthorizedException':
      if (call === AUTH_API.LOGIN) return t('Error_Cognito_IncorrectUsernamePassword');
      return t('Error_Cognito_NotAuthorized');
    case 'LimitExceededException':
    case 'TooManyFailedAttemptsException':
    case 'TooManyRequestsException':
      return t('Error_Cognito_Velocity');
    default:
      return handleCognitoCustomError(err.message);
  }
}

/**
 * Generates a cognito error handler callback that
 * takes in the error object from cognito and handles it accordingly by
 * calling either a custom handler or the generic handler.
 * The generic handler will parse the error object and
 * pass in the parsed error message into the errMsgCallback,
 * while also logging the API error event to Amplitude.
 *
 * @param {string} call name of the cognito call
 * @param {(parsedMessage:string)=>*} [errMsgCallback]
 * callback to run as part of the generic handler; will be passed in the parsed error message
 * @param {{ErrorCode:(error:{code:string;message:string;name:string})=>boolean}} [customHandlers]
 * key-value pairs for specific error handling,
 * where the key is the error code and the value is the custom handler for that error code
 * the return value of the handler indicates whether the error has been handled or not
 * @returns {(error:{code:string;message:string;name:string})=>*}
 */
export function genCognitoErrHandler(call, errMsgCallback, customHandlers) {
  return (err) => {
    let isHandled = false;
    if (customHandlers?.[err.code]) {
      // run custom handler
      isHandled = customHandlers[err.code](err);
    }

    if (isHandled) return false;

    // run generic handler
    const errMsg = parseCognitoError(err, call);
    logAmpEvent(events.API_ERROR, {
      Call: `Cognito: ${call}`,
      ResultCode: err.code,
      Description: errMsg,
    });
    errMsgCallback?.(errMsg);

    return false; // needed for ResendEmail component
  };
}

export function handleAPIError(call, res, errMsgCallback) {
  if (res.Result === 0) return true; // successful
  // run generic handler
  errMsgCallback?.(res.Description || t('Error_Default'));
  return false;
}

export function useFormExitConfirm() {
  const router = useRouter();
  const confirmationMsg = t('Purchase_FormExit_Confirmation');

  const handleRouteChange = useCallback((url) => {
    if (url === router.pathname.replace('[lang]', 'en') || url === router.pathname.replace('[lang]', 'fr')) return;

    if (!window.confirm(confirmationMsg)) { // eslint-disable-line no-alert
      // have to throw literal to prevent nextjs from complaining about uncaught runtime exception
      throw 'User aborted redirect.'; // eslint-disable-line no-throw-literal
    }
  }, [confirmationMsg, router.pathname]);

  const handleUnload = useCallback((e) => {
    e.preventDefault();
    e.returnValue = confirmationMsg;
  }, [confirmationMsg]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [handleRouteChange, handleUnload, router.events]);

  return () => {
    router.events.off('routeChangeStart', handleRouteChange);
    window.removeEventListener('beforeunload', handleUnload);
  };
}

/**
 * Call the function returned as the third item of the array to pop the state.
 * @param {*} [initialState] initial state
 * @returns {[*, function, function, function]} array where the first two items are the same as
 *  typical useState hook; third item is a function to set the state to the immediate previous,
 *  and fourth is a function to clear the history of the state
 */
export function useStateWithHistory(initialState) {
  const [stateHist, setStateHist] = useState([initialState]);
  return [
    stateHist.at(-1),
    (param) => {
      const newState = typeof param === 'function' ? param(stateHist.at(-1)) : param;
      setStateHist((hist) => [...hist, newState]);
    },
    () => setStateHist((hist) => hist.slice(0, -1)),
    (initialValue) => setStateHist([initialValue]),
  ];
}

export function formatCurrency(amount, omitDecimal) {
  const lang = getLanguage();
  const formatter = new Intl.NumberFormat(`${lang}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: omitDecimal ? 0 : 2,
    maximumFractionDigits: omitDecimal ? 0 : 2,
  });

  return formatter.format(amount);
}

export function isForeignTransaction(transactionCurrencyCode, billingCurrencyCode) {
  return transactionCurrencyCode !== billingCurrencyCode;
}

export function formatDateToHumanReadable(date) {
  return date.toLocaleString(getLanguage(), { dateStyle: 'long' });
}

export function formatTimestampToHumanReadable(timestamp) {
  return new Date(timestamp).toLocaleString(getLanguage(), { dateStyle: 'medium', timeStyle: 'short' });
}

export function formatTimestampToHumanReadable2(timestamp) {
  return new Date(timestamp).toLocaleString(getLanguage(), {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
}

export function scrollToTop(focusID = 'main-cont') {
  window.scrollTo(0, 0);
  moveFocusTo(focusID);
}

export function invalidFormAlert(invalid, errors, singleError = false) {
  let checkCount = 0;
  let alertString = '';
  if (invalid) {
    // focus on first invalid element
    const focusElem = document.getElementsByName(Object.keys(errors)[0])[0];
    focusElem.focus();
    focusElem.scrollIntoView({ behavior: 'smooth', block: 'center' });

    // loop though all validation errors
    Object.keys(errors).forEach((item) => {
      if (!singleError) {
        if (errors[item] !== true) {
          const e = document.getElementById(`${item}Lbl`).firstChild.textContent;
          alertString += `${e}, `;
        } else {
          checkCount += 1;
        }
      }
      logAmpEvent(events.USER_ENTERED_INVALID_VALUE, {
        Field: item,
        Reason: errors[item] !== true ? errors[item] : 'Required',
      });
    });
    if (singleError) {
      setScreenReaderAlert(Object.values(errors)[0]);
    } else {
      setScreenReaderAlert(checkCount === 0 ?
        t('ScreenReaderAlert_InvalidFormField').replace('xField', alertString) :
        `${t('ScreenReaderAlert_InvalidFormField').replace('xField', alertString)} ${t('ScreenReaderAlert_CheckAllCheckboxes').replace('xCount', checkCount)}`);
    }
  }
}

export function localStorageSet(key, value) {
  try {
    if (typeof localStorage === 'undefined') return false;
    localStorage.setItem(key, value);
    return true;
  } catch (err) {
    return false;
  }
}

export function localStorageGet(key) {
  try {
    if (typeof localStorage === 'undefined') return false;
    return localStorage.getItem(key);
  } catch (err) {
    return false;
  }
}

/**
 * If this function returns empty string, return from onSubmit (but keep loading state to true)
 * and let the ReCAPTCHA component's onChange callback trigger a resubmit.
 * When called during resubmit, a valid token will be returned by this function
 * so continue form submission with that token
 *
 * @param {*} reCaptchaRefValue the `.current` value of the ref passed into the ReCAPTCHA component
 * @param {() => *} onAbort callback to execute when user aborts reCAPTCHA challenge
 * @returns the reCAPTCHA token or empty string
 */
export function getReCaptchaToken(reCaptchaRefValue, onAbort) {
  // find the reCAPTCHA challenge window
  const list = document.querySelectorAll("iframe[src*='google.com/recaptcha/api2/bframe']");
  if (list.length > 0) {
    const recaptchaWindow = list[list.length - 1].parentNode.parentNode;

    new MutationObserver(([{ target }], observer) => {
      if (target.style.opacity === '0') { // challenge window closed
        logAmpEvent(events.GOOGLE_RECAPTCHA_CANCEL);
        onAbort();
        observer.disconnect();
      }
    }).observe(recaptchaWindow, { attributeFilter: ['style'] });
  }

  const reCaptchaToken = reCaptchaRefValue.getValue();

  if (!reCaptchaToken) {
    reCaptchaRefValue.executeAsync();
  } else {
    // reCaptchaToken will be sent to the API and consumed, so a new token is
    // required for the next submit. Reset in advance to make sure the
    // abort handler is attached to the new window.
    // Mutation observer attached to the previous window will also be disposed here
    reCaptchaRefValue.reset();
  }

  return reCaptchaToken;
}
