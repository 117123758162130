import { types } from '../actions/CardActions';

const initialState = {
  // undefined means info hasn't been loaded yet, null means card hasn't been linked yet
  proxy: undefined,
  status: null,
  balance: null,
  load: {
    max: 0,
    min: 0,
    fee: 0,
    status: null,
  },
  alerts: [],
  directDepositActive: false,
  directDeposit: {
    accountNumber: null,
    institution: null,
    transit: null,
    branchAddress: null,
  },
  account: {
    firstName: null,
    lastName: null,
    phoneNumber: null,
    street: null,
    poBox: null,
    city: null,
    province: null,
    postalCode: null,
  },
};

function CardReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_CARD_INFO:
      return action.payload;
    case types.NO_CARD_LINKED:
      return { ...state, proxy: null };
    case types.SET_CARD_STATUS:
      return { ...state, status: action.payload };
    case types.SET_DIRECT_DEPOSIT_INFO:
      return { ...state, directDeposit: action.payload };
    case types.SET_DIRECT_DEPOSIT_ACTIVE:
      return { ...state, directDepositActive: action.payload };
    case types.SET_ACCOUNT_INFO:
      return { ...state, account: action.payload };
    case types.UPDATE_PROXY:
      return { ...state, proxy: action.payload };
    case types.CLEAR_CARD_INFO:
    case types.CLEAR_SESSION:
      return initialState;
    default:
      return state;
  }
}

export default CardReducer;
